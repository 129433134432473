import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./system-and-user-reporting@gen.scss";

const GeneratedProductFeaturesSystemAndUserReporting = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          システム＆ユーザーレポート - PagerDuty正規代理店 - 株式会社Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="システムの効率を分析し、従業員の生産性を把握します。
"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="システムの効率を分析し、従業員の生産性を把握します。
"
        />

        <meta
          property="og:title"
          content="システム＆ユーザーレポート - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="システムの効率を分析し、従業員の生産性を把握します。
"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/system-and-user-reporting/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/system-and-user-reporting/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="システム＆ユーザーレポート"
        overview=""
      />

      <div className="saurWrapper">
        <div className="center-2 first-section">
          <h2 className="center-title">
            システムの効率を分析し、
            <br />
            従業員の生産性を把握します。
          </h2>
          <p className="center-para">
            システムレベルからユーザーレベルまでのパフォーマンスと効率を視覚化して分析します。
            レポートを表示して、運用効率、ユーザーの生産性、定期的なインシデントの傾向とパターンを測定します。
          </p>
        </div>
        {/* detail */}
        <div className="center-1">
          <h3 className="center-title">
            システムレポートとユーザーレポートの詳細
          </h3>
          <p className="center-para ">
            複数の詳細なシステムとユーザーのレポート機能を使用して、ユーザーの生産性を最適化して把握し、インシデント管理のレスポンスを改善します。
          </p>
        </div>
        <div className="three-col-without-cta">
          <div className="card">
            <div className="title">
              <p>
                アプリケーションと
                <br />
                システムの効率を分析
              </p>
            </div>
            <p>
              SLAホットスポットを見つけ、トレンドを分析し、困難なシステムやアプリケーションのパフォーマンスをトラックします。
              MTTRとMTTIの傾向を分析し、運用効率を測定、最適化します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>チームの生産性の傾向を評価</p>
            </div>
            <p>
              インシデントライフサイクル全体にわたる従業員のアジリティと生産性を学習し、非効率部分を見つけることで今後の解決時間を最適化{" "}
              <br /> します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>
                ワークロードのバランスと
                <br />
                チームヒーローの把握
              </p>
            </div>
            <p>
              レスポンダーが直面するインシデントの負荷とACKするまでの時間を理解し、必要に応じてワークストリームをロードバランスし、期日が来た時の従業員の生産性を把握します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>今後のために解決時間を改善</p>
            </div>
            <p>
              事前構成済みおよびカスタムの日付範囲にわたるインシデントボリュームの傾向を強調表示するレポートを表示し、非効率性を特定し、今後のためにインシデントの情報を記録します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>アラート分布の調査</p>
            </div>
            <p>
              SMS、電子メール、電話、およびプッシュアラートによるアラートのボリュームと分布を視覚化します。
            </p>
          </div>
        </div>
        <div className="conclusion">
          PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
          <br />
          インシデントをより迅速に解決するのに役立ちます。
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesSystemAndUserReporting;
